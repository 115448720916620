<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M6 0a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v4h-.586L4 6.586V5a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h1.586L4 9.414V13a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V9.414L11.414 8H13a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v1.586L8.586 8H8V4a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H6Zm0 10.25a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
